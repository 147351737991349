import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid';
import { useNavigate, Outlet } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import ScrollingFlashMessage from '../commonComponents/FlashBar';
import { setUnReadNotifications } from '../Slice/userSlice';
import { createSelector } from 'reselect';
import SideMenus from './pages/Layout/Menus';

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  borderRight: 'none',
  top: 'auto',
  bottom: 0
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  borderRight: 'none',
  top: 'auto',
  bottom: 0,
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const Layout = () => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  const apiCallCounts = useSelector(
    createSelector(
      (state) => state.user.refreshApiCall,
      (refreshApiCall) => refreshApiCall
    )
  );
  const redZonesData = useSelector(
    createSelector(
      (state) => state.user.redZones,
      (redZones) => redZones
    )
  );
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const vLoginData = useSelector(
    createSelector(
      (state) => state.user,
      (user) => user
    )
  );

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    // Initialize the socket connection
    const newSocket = io(process.env.REACT_APP_BASE_URL, {
      transports: ['websocket', 'polling']
    });
    setSocket(newSocket);

    newSocket.on('connect', () => {
      registerUserInSocket(newSocket);
    });

    newSocket.on('reconnect', () => {
      registerUserInSocket(newSocket);
    });

    newSocket.on('taskCreated', (message) => {
      showTaskCreationNotification(message);
    });

    newSocket.on('taskQualified', (message) => {
      showTaskCreationNotification(message);
    });

    newSocket.on('taskRejected', (message) => {
      showTaskCreationNotification(message);
    });

    newSocket.on('taskActivity', (message) => {
      showTaskCreationNotification(message);
    });
    newSocket.on('taskReminder', (message) => {
      showTaskCreationNotification(message);
    });

    return () => {
      newSocket.close();
    };
  }, [vLoginData?.userCd]);

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        console.log('Notification permission:', permission);
      });
    }
  }, []);

  const registerUserInSocket = (socketInstance) => {
    if (socketInstance && vLoginData?.userCd) {
      socketInstance.emit('register', { userID: vLoginData?.userCd });
    }
  };

  const showTaskCreationNotification = (data) => {
    if (Notification.permission === 'granted') {
      new Notification(`${data.title}`, {
        body: `${data.description}`,
        icon: `${window.location.origin}/favicon.ico`,
        tag: 'Gravity'
      });
      // Dispatch to increment unread count in Redux
      dispatch(setUnReadNotifications(vLoginData?.unreadNotifications + 1));
      console.log(vLoginData?.unreadNotifications);
    } else {
      console.error('Notification permission not granted');
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!vMediaQuery && (
        <Drawer
          className="drawerhead"
          variant="permanent"
          open={open}
          onMouseOver={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}>
          <DrawerHeader
            sx={{ display: 'flex', flexDirection: 'column', paddingTop: open ? 1 : '' }}>
            <Grid container>
              <Grid item xs={10} display={open ? 'flex' : 'none'}>
                <Typography padding={2}>Work Management</Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                  sx={{ paddingX: open ? '' : '8px', backgroundColor: '#f6f7fb' }}>
                  {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </DrawerHeader>
          <SideMenus />
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginTop: '10px',
          backgroundColor: theme.palette.background.page,
          marginLeft: vMediaQuery ? '0px' : '10px',
          borderTopLeftRadius: vMediaQuery ? '0px' : '8px',
          fontFamily: theme.palette.primary.font,
          height: `calc(100vh - 64px) !important`,
          width: vMediaQuery ? '100%' : `calc(${theme.spacing(4)} + 1px)`,
          [theme.breakpoints.up('sm')]: {
            width: vMediaQuery ? '100%' : `calc(${theme.spacing(5)} + 1px)`
          }
        }}>
        {redZonesData?.length > 0 && (
          <Box sx={{ paddingY: '10px', paddingX: '20px' }}>
            <ScrollingFlashMessage apiCallCounts={apiCallCounts} />
          </Box>
        )}

        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
