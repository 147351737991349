import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  summaryObj: {
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
    status: 'All'
  },
  summaryGridData:[]
};

const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    Set_Lead_SummaryObj_Init: (state, action) => {
      state.value.summaryObj = initialState.summaryObj;
    },

    Set_Lead_SummaryObj: (state, action) => {
      state.summaryObj = { ...state.summaryObj, ...action.payload };
    },
    Set_Lead_SummaryGridData: (state, action) => {
      state.summaryGridData = action.payload;
    }
     
  }
});

export const { Set_Lead_SummaryObj_Init, Set_Lead_SummaryObj, Set_Lead_SummaryGridData } = leadSlice.actions;
export default leadSlice.reducer;
