import React, { useEffect, useCallback, useMemo, useState } from 'react';
import Marquee from 'react-marquee-slider';
import { Box, Typography, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import theme from '../theme.js';
import { useSelector } from 'react-redux';
import UpdateFollowUpDrawer from '../commonComponents/UpdateFollowUpDrawer.jsx';
import { createSelector } from 'reselect';

const FlashMessageContainer = styled.div`
  background-color: ${theme.palette.background.default};
  border: ${theme.palette.shadow.flash};
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
`;

const ScrollingFlashMessage = ({ apiCallCounts }) => {
  const xMediaQuery = useMediaQuery('(max-width:450px)');
   const redZonesData = useSelector(
    createSelector(
      (state) => state.user?.redZones,
      (redZones) => redZones
    )
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [marqueeSpeed, setMarqueeSpeed] = useState(1); // Default speed
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // Memoized function to calculate marquee speed based on data length
  const calculateMarqueeSpeed = useCallback(() => {
    if (!redZonesData || redZonesData.length === 0) return 1;
    
    const totalLength = redZonesData.reduce((sum, item) => sum + (item.T_Desc?.length || 0), 0);
    console.log(totalLength);
    if (totalLength < 200) return 40;
    if (totalLength < 500) return 25;
    if (totalLength < 3000) return 2;
    return 1; // Default for larger content
    
  }, [redZonesData]);


  // Update marquee speed based on data content
  useEffect(() => {
    if (redZonesData && redZonesData.length > 0) {
      setIsDataLoaded(true); // Data is loaded
      setMarqueeSpeed(calculateMarqueeSpeed());
    }
  }, [redZonesData, calculateMarqueeSpeed]);

  // Handle marquee speed adjustment when hovering
  const handleMouseLeave = useCallback(() => {
    if (isDataLoaded) {
      setMarqueeSpeed(calculateMarqueeSpeed());
    }
  }, [isDataLoaded, calculateMarqueeSpeed]);

  const handleMouseEnter = useCallback(() => {
    setMarqueeSpeed(0); // Stop marquee on hover
  }, []);

  // Handle click event for flash messages
  const handleFlashClick = useCallback((msg) => {
    setSelectedTask(msg);
    setDrawerOpen(true);
  }, []);

  const marqueeContent = useMemo(
    () =>
      redZonesData?.map((msg, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: '30px'
          }}
          onClick={() => handleFlashClick(msg)}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.statusClr.red,
              marginRight: '10px',
              fontWeight: 'bold',
              fontFamily: theme.palette.primary.font
            }}>
            {msg.FinishedDaysPercentage}% -
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.secondary.text,
              fontWeight: 400,
              fontFamily: theme.palette.primary.font
            }}>
            {msg.T_Desc}
          </Typography>
        </Box>
      )),
    [redZonesData, handleFlashClick]
  );
  console.log(marqueeSpeed);

  return (
    <>
      {isDataLoaded && redZonesData?.length > 0 && (
        <FlashMessageContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Marquee velocity={marqueeSpeed} resetAfterTries={2}>
            {marqueeContent}
          </Marquee>
        </FlashMessageContainer>
      )}

      <UpdateFollowUpDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={() => setDrawerOpen(false)}
        selectedTask={selectedTask}
        xMediaQuery={xMediaQuery}
        setIsDrawerDataChange={() => {}}
        heading={'My Work'}
        apiCallCounts={apiCallCounts}
      />
    </>
  );
};

export default ScrollingFlashMessage;
