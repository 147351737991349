import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

const fAlertToast = (xIsSuccess, xMessage) => {
  let vToasterStyle = {
    position: 'top-right',
    autoClose: xIsSuccess === 'SUCCESS' ? 5000 : 8000, // Auto close after 5s for success, 8s for others
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };

  switch (xIsSuccess) {
    case 'SUCCESS':
      return toast.success(xMessage, vToasterStyle);
    case 'FAILED':
      return toast.error(xMessage, vToasterStyle);
    case 'WARNING':
      return toast.warning(xMessage, vToasterStyle);
    case 'INFO':
      return toast.info(xMessage, vToasterStyle);
    default:
      return toast.info(xMessage, vToasterStyle);
  }
};

export { fAlertToast };

export const getZoneColor = (percentage) => {
  if (percentage < 50) return 'Green';
  if (percentage < 75) return 'Yellow';
  if (percentage < 90) return 'Orange';
  return 'Red';
};

export const exportToExcel = (data, fileName = 'data', sheetName = 'Sheet1') => {
  const workSheet = XLSX.utils.json_to_sheet(data);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};
