import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import theme from '../../theme.js';
import Layout from '../Layout.jsx';
import React, { Suspense, lazy } from 'react';

// Lazy load components
const Home = lazy(() => import('../pages/home/Home.jsx'));
const MyWork = lazy(() => import('../pages/myWork/MyWork.jsx'));
const Login = lazy(() => import('../Login.jsx'));
const ActivityNotification = lazy(() => import('../pages/home/ActivityNotification.jsx'));
const Project = lazy(() => import('../pages/leadProject/Project.jsx'));
const LeadDetails = lazy(() => import('../pages/leadProject/LeadDetails.jsx'));
const AcitivitySummary = lazy(() => import('../pages/acitivities/AcitivitySummary.jsx'));
const SoftwareDevelopment = lazy(() => import('../pages/SoftwareDevelopment.jsx'));
const HeatMapSummary = lazy(() => import('../pages/heatMap/HeatMapSummary.jsx'));
const ClientVisitSummary = lazy(() => import('../pages/clientVisit/ClientVisitSummary.jsx'));
const AMCFollowUpSummary = lazy(() => import('../pages/amcFollowUp/AMCFollowUpSummary.jsx'));
const PdfViewer = lazy(() => import('../../commonComponents/PdfViewer.jsx'));

const ProjectRoutes = () => {
  const user = useSelector((state) => state.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/myWork" element={<MyWork />} />
          <Route path="/project" element={<Project />} />
          {isMobile && (
            <Route path="/todayActivity" element={<ActivityNotification user={user} />} />
          )}
          <Route path="/lead-details" element={<LeadDetails />} />
          <Route path="/acitivitySummary" element={<AcitivitySummary />} />
          <Route path="/software-management" element={<SoftwareDevelopment />} />
          <Route path="/heatMap" element={<HeatMapSummary />} />
          <Route path="/client-visit" element={<ClientVisitSummary />} />
          <Route path="/AMC-Followup" element={<AMCFollowUpSummary />} />
          <Route path="/preview-pdf" element={<PdfViewer />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};

export default ProjectRoutes;
