import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../theme.js';
import { CmpButton } from '../commonComponents/Toobox.js';
import { useDispatch } from 'react-redux';
import { setUser } from '../Slice/userSlice.js';
import { LoginTaskAPI } from '../Action.jsx';

const Login = () => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  const [userCd, setUserCd] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [usernameFocused, setUsernameFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordRef = useRef(null);

  const handleLogin = async (e) => {
    e.preventDefault();
  
    const obj = {
      userCd: userCd,
      password: password
    };
  
    const response = await LoginTaskAPI(obj);
  
    if (response.status === 200) {
      const userDetails = response.data.UserDetail;
      
      let filteredUsers = [];
      
      // Condition to check if userDetails.UId is '001'
      if (userDetails.UId === '001') {
        filteredUsers = response.data.users; // Get all users if UId === '001'
      } else {
        // Filter to only dispatch the logged-in user's details
        filteredUsers = response.data.users.filter(user => user.UId === userDetails.UId);
      }
  
      dispatch(
        setUser({
          userCd: userDetails.UId,
          userName: userDetails.Name1,
          password,
          rememberMe,
          isAuthenticated: true,
          Admin: userDetails.Admin,
          Superadmin: userDetails.Superadmin,
          LocationDetail: response.data.LocationDetail,
          users: response.data.users, // Store all users data
          filterData:filteredUsers, // Store the filtered users based on UId
          depts: response.data.depts,
          project: response.data.project,
          menu: response.data.menu,
          MGA: response.data.MGA,
          modelesArr:response.data.modulesList,
          taskType:response.data.taskType,
          softwareModules:response.data.softwareModules,
        })
      );  
      navigate('/myWork');
    }
  };
  

  const handleUserNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      passwordRef.current.focus();
    }
  };

  const handlePasswordKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin(e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('userId')?.focus();
    }, 1000);
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: '10px',
        backgroundColor: theme.palette.background.page,
        height: `calc(100vh - 64px) !important`
      }}>
      <div className="container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            border: `2px solid ${theme.palette.primary.main}`,
            boxShadow: theme.palette.shadow.main,
            padding: '20px',
            borderRadius: '8px',
            width: vMediaQuery ? '85%' : '400px',
            margin: '0 auto'
          }}>
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
          <TextField
            label="Username"
            id="userId"
            variant="outlined"
            value={userCd}
            onChange={(e) => setUserCd(e.target.value)}
            onFocus={() => setUsernameFocused(true)}
            onBlur={() => setUsernameFocused(false)}
            onKeyDown={handleUserNameKeyDown}
            style={{
              marginBottom: '16px',
              width: '100%',
              borderColor: usernameFocused ? '#2196F3' : '#ccc' // Blue border on focus
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setPasswordFocused(true)}
            onBlur={() => setPasswordFocused(false)}
            onKeyDown={handlePasswordKeyDown}
            inputRef={passwordRef}
            style={{
              marginBottom: '16px',
              width: '100%',
              borderColor: passwordFocused ? '#2196F3' : '#ccc' // Blue border on focus
            }}
          />
          <FormControlLabel
            control={
              <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
            }
            label="Remember Me"
            style={{ marginBottom: '16px', width: '100%' }}
          />
          <CmpButton xbtnName={'Login'} xOnClick={handleLogin} />
        </div>
      </div>
    </Box>
  );
};

export default Login;
