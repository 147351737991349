import React, { lazy, Suspense, useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import theme from '../theme.js';
import { useDispatch, useSelector } from 'react-redux';
import { resetUser, setNotificationData, setUnReadNotifications } from '../Slice/userSlice.js';
import { useMediaQuery } from '@mui/material';
import { todayNotificationAPI } from '../Action.jsx';
import { createSelector } from 'reselect';
import SideMenus from './pages/Layout/Menus.jsx';

// Lazy load components
const FilterModal = lazy(() => import('../commonComponents/FilterModal.jsx'));
const NotificationDrawer = lazy(() => import('../commonComponents/NotificationDrawer.jsx'));

const Header = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:450px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notifyDrawer, setNotifyDrawerOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const user = useSelector(
    createSelector(
      (state) => state.user,
      (user) => user
    )
  );
  const unRead = useSelector(
    createSelector(
      (state) => state.user?.unreadNotifications,
      (unreadNotifications) => unreadNotifications
    )
  );
  const FilterModalMemo = React.memo(FilterModal);
  const NotificationDrawerMemo = React.memo(NotificationDrawer);

  const handleLogout = () => {
    dispatch(resetUser());
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      const result = await todayNotificationAPI({ user_id: user?.userCd });
      if (result?.status === 200) {
        dispatch(setNotificationData(result?.data?.message));

        const unreadCount = result.data.message.filter((n) => n.N_IsRead === null).length;
        dispatch(setUnReadNotifications(unreadCount));
      }
    };

    fetchNotifications();
  }, []);

  const handleFilterClose = () => {
    setFilterOpen(!filterOpen);
  };

  const handleNotifyDrawer = () => {
    setNotifyDrawerOpen(!notifyDrawer);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBar position="static" sx={{ color: '#fff' }} className="toolbar">
        <Toolbar>
          {isMobile && (
            <IconButton
              sx={{
                '&.MuiIconButton-root': {
                  color: theme.palette.primary.text,
                  padding: isMobile ? '2px' : ''
                }
              }}
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant={isMobile ? 'subtitle1' : 'h6'}
            sx={{ flexGrow: 1, fontSize: isMobile ? '15px !important' : '1rem' }}>
            Work Management
          </Typography>
          <Typography
            variant={isMobile ? 'body2' : 'h6'}
            sx={{ flexGrow: 1, mt: isMobile ? '2px' : '', fontSize: isMobile ? '12px' : '18px' }}>
            {user?.userName}
            {user?.userCd === '001' && (
              <IconButton
                className="filterIcon"
                onClick={() => {
                  setFilterOpen(true);
                }}
                sx={{
                  '&.MuiIconButton-root': {
                    color: user?.userCd !== '001' ? '#ffffff5e' : theme.palette.primary.text,
                    marginX: isMobile ? '' : '2px !important',
                    marginBottom: isMobile ? '' : '4px !important',
                    padding: '0px !important'
                  }
                }}>
                <FilterAltOutlinedIcon />
              </IconButton>
            )}
          </Typography>
          <IconButton
            className="notify-count"
            sx={{
              '&.MuiIconButton-root': {
                color: theme.palette.primary.text,
                paddingRight: isMobile ? '16px' : '30px',
                paddingLeft: isMobile ? '0px' : '0px'
              }
            }}
            aria-label="logout"
            onClick={handleNotifyDrawer}>
            <Badge
              overlap="circular"
              badgeContent={unRead} // Show unread count on Un-Read chip
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {isMobile ? (
            <IconButton
              sx={{
                '&.MuiIconButton-root': {
                  color: theme.palette.primary.text,
                  padding: '0px !important'
                }
              }}
              aria-label="logout"
              onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          ) : (
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {filterOpen && (
        <Suspense fallback={<p className='loading'>Loading...</p>}>
          <FilterModalMemo
            user={user}
            isMobile={isMobile}
            open={filterOpen}
            handleClose={handleFilterClose}
          />
        </Suspense>
      )}
      {notifyDrawer && (
        <Suspense fallback={<p className='loading'>Loading...</p>}>
          <NotificationDrawerMemo
            notifyDrawer={notifyDrawer}
            handleNotifyDrawerClose={() => setNotifyDrawerOpen(false)}
            user={user}
            xMediaQuery={isMobile}
          />
        </Suspense>
      )}

      <Drawer
        className="modal"
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '210px',
            height: 'calc(100vh - 64px)',
            top: '64px',
            borderTopRightRadius: '10px',
            backgroundColor: theme.palette.background.page
          },
          '& .MuiModal-backdrop': {
            backgroundColor: 'none !important'
          }
        }}>
           <SideMenus onClose={handleDrawerToggle}/>
      </Drawer>
    </>
  );
};

export default Header;
