// src/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userCd: '',
  password: '',
  userName: '',
  rememberMe: false,
  isAuthenticated: false,
  Admin: 'N',
  Superadmin: 'N',
  LocationDetail: [],
  users: [],
  depts: [],
  project: [],
  menu:[],
  MGA:[],
  modelesArr:[],
  taskType:[],
  softwareModules:[],
  refreshApiCall: {
    myWorkRefreshCount: 0,
    mySupervisingRefreshCount: 0
  },
  filterData: [],
  redZones: [],
  notificationData:[],
  unreadNotifications:0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetUser: () => initialState,
    setRefeshApiCall: (state, action) => {
      state.refreshApiCall = { ...state.refreshApiCall, ...action.payload };
    },
    setFilteredUsers: (state, action) => {
      state.filterData = action.payload;
    },
    setRedZones: (state, action) => {
      state.redZones = action.payload;
    },
    setNotificationData: (state, action) => {
      state.notificationData = action.payload;
    },
    setUnReadNotifications : (state, action) => {
      state.unreadNotifications = action.payload;
    }
  }
});

export const { setUser, resetUser, setRefeshApiCall, setFilteredUsers, setRedZones, setNotificationData, setUnReadNotifications } =
  userSlice.actions;
export default userSlice.reducer;
