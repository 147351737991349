import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  priority: [],
  status: [],
  zone: [],
  gridData: [],
};

const mySupervisingSlice = createSlice({
  name: 'mySupervising',
  initialState,
  reducers: {
    setPriority: (state, action) => {
      state.priority = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setZone: (state, action) => {
      state.zone = action.payload;
    },
    setGridData: (state, action) => {
      state.gridData = action.payload;
    },
    setMySupervisingTaskGridDataUpdate: (state, action) => {
      const { T_ID, updatedData } = action.payload;
      state.gridData = state.gridData.map((task) =>
        task.T_ID === T_ID ? { ...task, ...updatedData } : task
      );
    },
    resetMyTaskState: () => initialState,
  },
});

export const { setPriority, setStatus, setZone, setGridData, setMySupervisingTaskGridDataUpdate, resetMyTaskState } = mySupervisingSlice.actions;
export default mySupervisingSlice.reducer;
